import React, { createContext } from "react";
import { Routes, Route } from "react-router-dom";
import useData from "./routes/customHook/useData";
import useWomendata from "./routes/customHook/useWomendata";
import useClub from "./routes/customHook/useClub";
import useW_Club from "./routes/customHook/useW_club";
import useNews from "./routes/customHook/useNews";
import usePlayers from "./routes/customHook/usePlayer";
import Initail from "./routes/initial";
import Live from "./routes/common/liveData";
import News from "./routes/common/news";
import SignUP from "./routes/createUser";
import Fixture from "./routes/fixture";
import Fixturefot from "./routes/common/fixturefot";
import Newspage from "./routes/newsPage";
import Changepass from "./routes/common/changepassword";
import Editnews from "./routes/common/editnews";
import Clubs from "./routes/clubs";
import Users from "./routes/users";
import Players from "./routes/player";
import Formation from "./routes/formation";
import WomenPlayers from "./routes/womenPlayer";
import BDKNews from "./routes/common/bdkNews";
import WomenClubs from "./routes/womenClubs";
import WomenFixture from "./routes/womenFixture";
import "./App.css";

const MyContext = createContext();
const App = () => {
  const news = useNews();
  const data = useData();
  const womendata = useWomendata();
  const club = useClub();
  const womenclub = useW_Club();
  const Player = usePlayers();

  return (
    <MyContext.Provider
      value={{ data, news, club, Player, womenclub, womendata }}
    >
      <Routes>
        <Route path="/" element={<Initail />}>
          <Route index element={<Newspage />} />
          <Route path="news" element={<News />} />
          <Route path="bdkNews" element={<BDKNews />} />
          <Route path="users" element={<Users />}>
            <Route path="signup" element={<SignUP />} />
          </Route>
          <Route path="changepassword" element={<Changepass />} />
          <Route path="editnews/:id" element={<Editnews />} />
          <Route path="fixturefot" element={<Fixturefot />} />
          <Route path="liveData/:id" element={<Live />} />
          <Route path="formation/:id" element={<Formation />} />
          <Route path="fixture" element={<Fixture />} />
          <Route path="womenFixture" element={<WomenFixture />} />
          <Route path="clubs" element={<Clubs />} />
          <Route path="womenClubs" element={<WomenClubs />} />
          <Route path="player" element={<Players />} />
          <Route path="womenPlayer" element={<WomenPlayers />} />
        </Route>
      </Routes>
    </MyContext.Provider>
  );
};

export { App, MyContext };
