import http from "./httpService";
import config from "../../config.json";

const apiFootball = config.apiUrl + "/round";
const apiwomenFootball = config.apiUrl + "/womenround";
const apiNews = config.apiUrl + "/news";
const apiClub = config.apiUrl + "/club";
const apiw_Club = config.apiUrl + "/womenclub";
const apiGoal = config.apiUrl + "/goal";
const apiRed = config.apiUrl + "/redcard";
const apiYellow = config.apiUrl + "/yellowcard";
const apiPlayer = config.apiUrl + "/player";
const apiwomenPlayer = config.apiUrl + "/womenplayer";
const apiSubs = config.apiUrl + "/substitution";
const apiFormation = config.apiUrl + "/formation";
const apibdkNews = config.apiUrl + "/bdkNews";

export async function register(data) {
  try {
    return await http.post(apiFootball, data);
  } catch (error) {
    alert(error);
  }
}
export async function getData(id) {
  try {
    return await http.get(apiFootball + "/" + id);
  } catch (error) {
    alert(error);
  }
}
export async function getDatas() {
  try {
    return await http.get(apiFootball);
  } catch (error) {
    alert(error);
  }
}
export async function update(data) {
  try {
    const fdata = { ...data };
    delete fdata.id;
    return await http.put(apiFootball + "/" + data.id, fdata);
  } catch (error) {
    alert(error);
  }
}
export async function registerWomen(data) {
  try {
    return await http.post(apiwomenFootball, data);
  } catch (error) {
    alert(error);
  }
}
export async function getwomenData(id) {
  try {
    return await http.get(apiwomenFootball + "/" + id);
  } catch (error) {
    alert(error);
  }
}
export async function getwomenDatas() {
  try {
    return await http.get(apiwomenFootball);
  } catch (error) {
    alert(error);
  }
}
export async function updatewomen(data) {
  try {
    const fdata = { ...data };
    delete fdata.id;
    return await http.put(apiwomenFootball + "/" + data.id, fdata);
  } catch (error) {
    alert(error);
  }
}

export async function registerGoal(data) {
  try {
    return await http.post(apiGoal, data);
  } catch (error) {
    alert(error);
  }
}
export async function registerRed(data) {
  try {
    return await http.post(apiRed, data);
  } catch (error) {
    alert(error);
  }
}
export async function registerYellow(data) {
  try {
    return await http.post(apiYellow, data);
  } catch (error) {
    alert(error);
  }
}

export async function registerSubs(data) {
  try {
    return await http.post(apiSubs, data);
  } catch (error) {
    alert(error);
  }
}

export async function registerNews(data) {
  try {
    return await http.post(apiNews, data);
  } catch (error) {
    alert(error);
  }
}
export async function registerbdkNews(data) {
  try {
    return await http.post(apibdkNews, data);
  } catch (error) {
    alert(error);
  }
}

export async function getNews() {
  try {
    return await http.get(apiNews);
  } catch (error) {
    alert(error);
  }
}
export async function getbdkNews() {
  try {
    return await http.get(apibdkNews);
  } catch (error) {
    alert(error);
  }
}

// clubs data
export async function getClubs() {
  try {
    return await http.get(apiClub);
  } catch (error) {
    alert(error);
  }
}
export async function getClub(id) {
  try {
    return await http.get(apiClub + "/" + id);
  } catch (error) {
    alert(error);
  }
}
export async function registerClub(data) {
  try {
    return await http.post(apiClub, data);
  } catch (error) {
    alert(error);
  }
}
export async function updateClub(data) {
  try {
    const fdata = { ...data };
    delete fdata.id;
    return await http.put(apiClub + "/" + data.id, fdata);
  } catch (error) {
    alert(error);
  }
}
export async function DeleteClub(id) {
  try {
    return await http.delete(apiClub + "/" + id);
  } catch (error) {
    alert(error);
  }
}
export async function getw_Clubs() {
  try {
    return await http.get(apiw_Club);
  } catch (error) {
    alert(error);
  }
}
export async function getw_Club(id) {
  try {
    return await http.get(apiw_Club + "/" + id);
  } catch (error) {
    alert(error);
  }
}
export async function registerw_Club(data) {
  try {
    return await http.post(apiw_Club, data);
  } catch (error) {
    alert(error);
  }
}
export async function updatew_Club(data) {
  try {
    const fdata = { ...data };
    delete fdata.id;
    return await http.put(apiw_Club + "/" + data.id, fdata);
  } catch (error) {
    alert(error);
  }
}
export async function Deletew_Club(id) {
  try {
    return await http.delete(apiw_Club + "/" + id);
  } catch (error) {
    alert(error);
  }
}

// players data
export async function getPlayers() {
  try {
    return await http.get(apiPlayer);
  } catch (error) {
    alert(error);
  }
}

export async function getwomenPlayer(id) {
  try {
    return await http.get(apiwomenPlayer + "/" + id);
  } catch (error) {
    alert(error);
  }
}
export async function registerPlayer(data) {
  try {
    return await http.post(apiPlayer, data);
  } catch (error) {
    alert(error);
  }
}
export async function registerwomenPlayer(data) {
  try {
    return await http.post(apiwomenPlayer, data);
  } catch (error) {
    alert(error);
  }
}

export async function updatePlayer(data) {
  try {
    const fdata = { ...data };
    delete fdata.id;
    return await http.put(apiPlayer + "/" + data.id, fdata);
  } catch (error) {
    alert(error);
  }
}
export async function updatewomenPlayer(data) {
  try {
    const fdata = { ...data };
    delete fdata.id;
    return await http.put(apiwomenPlayer + "/" + data.id, fdata);
  } catch (error) {
    alert(error);
  }
}

export async function DeletePlayer(id) {
  try {
    return await http.delete(apiPlayer + "/" + id);
  } catch (error) {
    alert(error);
  }
}
export async function DeletewomenPlayer(id) {
  try {
    return await http.delete(apiwomenPlayer + "/" + id);
  } catch (error) {
    alert(error);
  }
}

export async function getFormations() {
  try {
    return await http.get(apiFormation);
  } catch (error) {
    alert(error);
  }
}

export async function getFormation(id) {
  try {
    return await http.get(apiFormation + "/" + id);
  } catch (error) {
    alert(error);
  }
}
export async function registerFormation(data) {
  try {
    return await http.post(apiFormation, data);
  } catch (error) {
    alert(error);
  }
}

export async function updateFormation(data) {
  try {
    const fdata = { ...data };
    delete fdata.id;
    return await http.put(apiFormation + "/" + data.id, fdata);
  } catch (error) {
    alert(error);
  }
}

export async function DeleteFormation(id) {
  try {
    return await http.delete(apiFormation + "/" + id);
  } catch (error) {
    alert(error);
  }
}
