import { NavLink } from "react-router-dom";
import { getCurrentUser, logout } from "./services/authService";

const Sidebar = () => {
  const data = getCurrentUser();
  const Loggedout = () => {
    try {
      logout();
      window.location = "/";
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="sidebar">
      <div>
        <NavLink className="btnside" to="/">
          Home
        </NavLink>
        <NavLink className="btnside" to="fixturefot">
          Live
        </NavLink>
        <NavLink className="btnside" to="news">
          Upload News
        </NavLink>
        <NavLink className="btnside" to="bdkNews">
          Upload BDKNews
        </NavLink>
        <NavLink className="btnside" to="fixture">
          Men-Round Data
        </NavLink>
        <NavLink className="btnside" to="womenFixture">
          Women-Round Data
        </NavLink>
        <NavLink className="btnside" to="clubs">
          Men Clubs Data
        </NavLink>
        <NavLink className="btnside" to="womenClubs">
          Women Clubs Data
        </NavLink>
        <NavLink className="btnside" to="player">
          Men-Players Data
        </NavLink>
        <NavLink className="btnside" to="womenPlayer">
          Women-Players Data
        </NavLink>
        {data.isAdmin === true && (
          <NavLink className="btnside" to="users">
            Users
          </NavLink>
        )}
      </div>
      <div>
        <p>
          <span>{data.name}</span>
          <span>{data.phone}</span>
        </p>
        <NavLink className="btnside" to="changepassword">
          Change Password
        </NavLink>
        <button onClick={() => Loggedout()}>LogOut</button>
      </div>
    </div>
  );
};

export default Sidebar;
