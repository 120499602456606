import { useState } from "react";
import axios from "axios";
import Input from "./input";
import TextArea from "./textArea";
import Select from "./select";
import config from "../../config.json";
import { ToastContainer, toast } from "react-toastify";

const apiNews = config.apiUrl + "/bdkNews";
const BDKNews = () => {
  const [data, setData] = useState({
    headline: "",
    catagory: "",
    author: "",
    content: "",
  });
  const [file, setFile] = useState(null);

  const handleDataChange = ({ target: { name, value } }) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("headline", data.headline);
      formData.append("catagory", data.catagory);
      formData.append("author", data.author);
      formData.append("content", data.content);
      formData.append("file", file);

      await axios.post(apiNews, formData);
      toast.success("File uploaded successfully!");

      setData({ headline: "", catagory: "", author: "", content: "" });
      setFile(null);
    } catch (error) {
      toast.error("Error uploading file. Please try again.");
    }
  };

  const renderInput = (name, label, type = "text") => (
    <Input
      onChange={handleDataChange}
      label={label}
      type={type}
      name={name}
      value={data[name]}
    />
  );

  const renderTextarea = (name, label, type = "text") => (
    <TextArea
      onChange={handleDataChange}
      label={label}
      type={type}
      name={name}
      value={data[name]}
    />
  );

  const renderSelect = (name, label, options) => (
    <Select
      onChange={handleDataChange}
      label={label}
      options={options}
      name={name}
      value={data[name]}
    />
  );

  return (
    <div className="news">
      <ToastContainer position="top-right" autoClose={3000} />
      <form
        onSubmit={handleFormSubmit}
        encType="multipart/form-data"
        className="picForm"
      >
        {renderInput("headline", "Headline")}
        {renderSelect("catagory", "Catagory", [
          "MP",
          "WP",
          "ETCUP",
          "MHL",
          "WHL",
          "MNT",
          "WNT",
          "MSL",
          "WSL",
        ])}
        {renderTextarea("content", "Content")}
        {renderInput("author", "Author")}
        <label htmlFor="file" className="custom-file-upload">
          {file ? file.name : "Upload header image"}
        </label>
        <input
          className="file-upload-input"
          type="file"
          name="file"
          id="file"
          required
          accept="image/*"
          onChange={handleFileChange}
        />
        <button className="upload-button" type="submit">
          Upload
        </button>
      </form>
    </div>
  );
};

export default BDKNews;
