import { useContext } from "react";
import { MyContext } from "../App";
import Fixturedata from "./common/fixtureData";
import Logourl from "./common/logourl";
import { register } from "./services/footballData";

const Fixture = () => {
  const { club } = useContext(MyContext);
  return (
    <div className="dis">
      <Fixturedata register={register} />
      <Logourl club={club} />
    </div>
  );
};

export default Fixture;
