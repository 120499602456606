import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Input from "../routes/common/input";
import config from "../config.json";
import Select from "./common/select";

const apiClub = config.apiUrl + "/womenclub";
const WomenClubs = () => {
  const [data, setData] = useState({
    clubname: "",
    clubID: "",
    country: "",
    founded: "",
    shortCode: "",
  });
  const [file, setFile] = useState(null);

  const handleDataChange = ({ target: { name, value } }) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("clubname", data.clubname);
      formData.append("clubID", data.clubID);
      formData.append("country", data.country);
      formData.append("founded", data.founded);
      formData.append("shortCode", data.shortCode);
      formData.append("file", file);

      await axios.post(apiClub, formData);
      toast.success("File uploaded successfully!");

      setData({
        clubname: "",
        clubID: "",
        country: "",
        founded: "",
        shortCode: "",
      });
      setFile(null);
    } catch (error) {
      toast.error("Error uploading file. Please try again.");
    }
  };

  const renderInput = (name, label, type = "text") => (
    <Input
      onChange={handleDataChange}
      label={label}
      type={type}
      name={name}
      value={data[name]}
    />
  );
  const renderSelect = (name, label, options) => (
    <Select
      onChange={handleDataChange}
      label={label}
      options={options}
      name={name}
      value={data[name]}
    />
  );

  return (
    <div className="news">
      <ToastContainer position="top-right" autoClose={3000} />
      <form
        onSubmit={handleFormSubmit}
        encType="multipart/form-data"
        className="picForm"
      >
        {renderInput("clubname", "Clubname")}
        {renderSelect("clubID", "ClubID", [
          "EP-01",
          "EP-02",
          "EP-03",
          "EP-04",
          "EP-05",
          "EP-06",
          "EP-07",
          "EP-08",
          "EP-09",
          "EP-10",
          "EP-11",
          "EP-12",
          "EP-13",
          "EP-14",
        ])}
        {renderInput("country", "Country")}
        {renderInput("founded", "Founded")}
        {renderInput("shortCode", "Short Name")}
        <label htmlFor="file" className="custom-file-upload">
          {file ? file.name : "select club logo"}
        </label>
        <input
          className="file-upload-input"
          type="file"
          name="file"
          id="file"
          required
          accept="image/*"
          onChange={handleFileChange}
        />
        <button className="upload-button" type="submit">
          Upload
        </button>
      </form>
    </div>
  );
};

export default WomenClubs;
