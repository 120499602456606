import { Outlet } from "react-router-dom";
import useAdmin from "./customHook/useAdmin";
import Login from "./loginForm";
import Navbar from "./navbar";
import Sidebar from "./sidebar";
// import Livecomp from "./livecomp";

const Initail = () => {
  const admin = useAdmin();
  const Getadmin = () => {
    if (admin) {
      return (
        <>
          <>
            <Sidebar />
            <div className="main">
              <Navbar />
              {/* <Livecomp /> */}
              <Outlet />
            </div>
          </>
        </>
      );
    }
    return (
      <>
        <Login />
      </>
    );
  };
  return Getadmin();
};

export default Initail;
