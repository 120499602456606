import { useContext } from "react";
import { MyContext } from "../App";
import Fixturedata from "./common/fixtureData";
import Logourl from "./common/logourl";
import { registerWomen } from "./services/footballData";

const WomenFixture = () => {
  const { womenclub } = useContext(MyContext);
  return (
    <div className="dis">
      <Fixturedata register={registerWomen} />
      <Logourl club={womenclub} />
    </div>
  );
};

export default WomenFixture;
