import { useState } from "react";
import axios from "axios";
import Input from "./common/input";
import Select from "./common/select";
import config from "../config.json";
import { ToastContainer, toast } from "react-toastify";

const apiPlayers = config.apiUrl + "/womenplayer";
const WomenPlayers = () => {
  const [data, setData] = useState({
    name: "",
    dateOfBirth: "",
    club: "",
    nationality: "",
    number: "",
    position: "",
  });
  const [file, setFile] = useState(null);

  const handleDataChange = ({ target: { name, value } }) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (
      !data.name ||
      !data.dateOfBirth ||
      !data.club ||
      !data.nationality ||
      !data.number ||
      !data.position ||
      !file
    ) {
      toast.error("Please fill in all fields and upload an image.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("dateOfBirth", data.dateOfBirth);
      formData.append("club", data.club);
      formData.append("nationality", data.nationality);
      formData.append("number", data.number);
      formData.append("position", data.position);
      formData.append("file", file);
      await axios.post(apiPlayers, formData);
      toast.success("File uploaded successfully!");
      setData({
        name: "",
        dateOfBirth: "",
        club: "",
        nationality: "",
        number: "",
        position: "",
      });
      setFile(null);
    } catch (error) {
      const message = error.response
        ? error.response.data
        : "Error uploading file. Please try again.";
      toast.error(message);
    }
  };

  const renderInput = (name, label, type = "text") => (
    <Input
      onChange={handleDataChange}
      label={label}
      type={type}
      name={name}
      value={data[name]}
    />
  );

  const renderSelect = (name, label, options) => (
    <Select
      onChange={handleDataChange}
      label={label}
      options={options}
      name={name}
      value={data[name]}
    />
  );

  return (
    <div className="news">
      <ToastContainer position="top-right" autoClose={3000} />
      <form
        onSubmit={handleFormSubmit}
        encType="multipart/form-data"
        className="picForm"
      >
        {renderInput("name", "Name")}
        {renderInput("club", "Club")}
        {renderInput("dateOfBirth", "Date Of Birth (yyyy-mm-dd)", "date")}
        {renderInput("nationality", "Nationality")}
        {renderInput("number", "Number")}
        {renderSelect("position", "Position", [
          "Head Coach",
          "GK",
          "DF",
          "MF",
          "F",
        ])}
        <label htmlFor="file" className="custom-file-upload">
          {file ? file.name : "Upload Player image"}
        </label>
        <input
          className="file-upload-input"
          type="file"
          name="file"
          id="file"
          required
          accept="image/*"
          onChange={handleFileChange}
        />
        <button className="upload-button" type="submit">
          Upload
        </button>
      </form>
    </div>
  );
};

export default WomenPlayers;
